<template>
    <div>
        <br>
        <NewsView :item="newsDetail" />
        <br>
    </div>
</template>

<script>
import NewsView from '../components/NewsView';


import { mapActions, mapState } from 'vuex';

export default {
    data: () => ({}),
    created () {
        const id = this.$route.params.id;
        this.findNews({ id: id, });
        this.timeoutId = setTimeout(() => {
            this.markNewsAsViewd({ id })
        }, 30000)
    },
    beforeDestroy() {
        clearTimeout(this.timeoutId)
    },
    computed: mapState('news', ['newsDetail', 'loading']),
    methods: { 
        ...mapActions('news', {
            findNews: 'detail',
            markNewsAsViewd: 'markNewsAsViewd'
        }),
    },
    components: {
        NewsView,
    },
}
</script>