<template>
    <v-container v-if="item" class="news">
        <v-row>
            <v-col cols="12">
                <v-btn icon to="/" text title="Ir para Início">
                    <font-awesome-icon icon="arrow-left"  size="2x" class="text-primary"/>
                </v-btn>
                <br> <br>
                <v-row>
                    <v-col></v-col>
                    <v-col cols="9" xl="6">
                        
                        <p  style="font-size: 13px;">{{ item.date }}</p>
                        <h1 >{{ item.title }}</h1>
                        <h2 >{{ item.subTitle }}</h2>
                        <br>
                        <div :v-html="item.sinopse"></div>
                    </v-col>
                    <v-col></v-col>
                </v-row>
                <br>
                <v-row justify="center">
                        <v-img 
                            :src="item.src" 
                            style="border-radius: 2px;" 
                            height="500px" max-width="1000px"/>
                </v-row>
                <br>

                <v-row>
                    <v-col></v-col>
                    <v-col cols="9" xl="6">
                        <div class="news-body text-justify" v-html="item.body">
                        </div>
                    </v-col>
                    <v-col></v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    props: ['item',],
}
</script>